define("discourse/plugins/discourse-locations/discourse/initializers/location-edits", ["exports", "discourse/models/composer", "discourse/models/nav-item", "discourse/raw-views/topic-status", "discourse-common/utils/decorators", "discourse/lib/plugin-api", "discourse/plugins/discourse-locations/discourse/lib/location-utilities", "@ember/runloop", "I18n"], function (_exports, _composer, _navItem, _topicStatus, _decorators, _pluginApi, _locationUtilities, _runloop, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = {
    name: "location-edits",
    initialize(container) {
      var _obj6;
      const siteSettings = container.lookup("site-settings:main");
      const site = container.lookup("site:main");
      (0, _pluginApi.withPluginApi)("0.8.23", api => {
        var _dec, _dec2, _obj, _dec3, _dec4, _obj2, _init, _dec5, _obj3, _dec6, _obj4, _dec7, _obj5;
        api.decorateWidget("post-body:after-meta-data", helper => {
          const model = helper.getModel();
          if (siteSettings.location_user_post && model.user_custom_fields && model.user_custom_fields["geo_location"]) {
            let format = siteSettings.location_user_post_format.split("|");
            let opts = {};
            if (format.length) {
              opts["geoAttrs"] = format;
            }
            let locationText = (0, _locationUtilities.geoLocationFormat)(model.user_custom_fields["geo_location"], site.country_codes, opts);
            return helper.h("div.user-location", locationText);
          }
        });
        api.modifyClass("controller:users", {
          pluginId: "locations-plugin",
          loadUsers(params) {
            if (params !== undefined && params.period === "location") {
              return;
            }
            this._super(params);
          }
        });
        api.modifyClass("model:composer", (_dec = (0, _decorators.default)("subtype", "categoryId", "topicFirstPost", "forceLocationControls"), _dec2 = (0, _decorators.observes)("draftKey"), (_obj = {
          pluginId: "locations-plugin",
          showLocationControls(subtype, categoryId, topicFirstPost, force) {
            if (!topicFirstPost) {
              return false;
            }
            if (force) {
              return true;
            }
            if (categoryId) {
              const category = this.site.categories.findBy("id", categoryId);
              if (category && category.custom_fields.location_enabled) {
                return true;
              }
            }
            return false;
          },
          clearState() {
            this._super(...arguments);
            this.set("location", null);
          },
          _setupDefaultLocation() {
            if (this.draftKey === "new_topic") {
              const topicDefaultLocation = this.siteSettings.location_topic_default;
              // NB: we can't use the siteSettings, nor currentUser values set in the initialiser here
              // because in QUnit they will not be defined as the initialiser only runs once
              // so this will break all tests, even if in runtime it may work.
              // so solution is to use the values provided by the Composer model under 'this'.
              if (topicDefaultLocation === "user" && this.user.custom_fields.geo_location && (typeof this.user.custom_fields.geo_location === "string" && this.user.custom_fields.geo_location.replaceAll(" ", "") !== "{}" || typeof this.user.custom_fields.geo_location === "object" && Object.keys(this.user.custom_fields.geo_location).length !== 0)) {
                this.set("location", {
                  geo_location: this.user.custom_fields.geo_location
                });
              }
            }
          }
        }, (_applyDecoratedDescriptor(_obj, "showLocationControls", [_dec], Object.getOwnPropertyDescriptor(_obj, "showLocationControls"), _obj), _applyDecoratedDescriptor(_obj, "_setupDefaultLocation", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_setupDefaultLocation"), _obj)), _obj)));
        api.modifyClass("component:composer-body", (_dec3 = (0, _decorators.observes)("composer.location"), _dec4 = (0, _decorators.observes)("composer.showLocationControls", "composer.composeState"), (_obj2 = {
          pluginId: "locations-plugin",
          resizeWhenLocationAdded: function () {
            this._triggerComposerResized();
          },
          applyLocationInlineClass() {
            (0, _runloop.scheduleOnce)("afterRender", this, () => {
              const showLocationControls = this.get("composer.showLocationControls");
              const $container = $(".composer-fields .title-and-category");
              $container.toggleClass("show-location-controls", showLocationControls);
              if (showLocationControls) {
                const $anchor = this.site.mobileView ? $container.find(".title-input") : $container;
                $(".composer-controls-location").appendTo($anchor);
              }
              this._triggerComposerResized();
            });
          }
        }, (_applyDecoratedDescriptor(_obj2, "resizeWhenLocationAdded", [_dec3], (_init = Object.getOwnPropertyDescriptor(_obj2, "resizeWhenLocationAdded"), _init = _init ? _init.value : undefined, {
          enumerable: true,
          configurable: true,
          writable: true,
          initializer: function () {
            return _init;
          }
        }), _obj2), _applyDecoratedDescriptor(_obj2, "applyLocationInlineClass", [_dec4], Object.getOwnPropertyDescriptor(_obj2, "applyLocationInlineClass"), _obj2)), _obj2)));
        const subtypeShowLocation = ["event", "question", "general"];
        api.modifyClass("model:topic", (_dec5 = (0, _decorators.default)("subtype", "category.custom_fields.location_enabled"), (_obj3 = {
          pluginId: "locations-plugin",
          showLocationControls(subtype, categoryEnabled) {
            return subtypeShowLocation.indexOf(subtype) > -1 || categoryEnabled;
          }
        }, (_applyDecoratedDescriptor(_obj3, "showLocationControls", [_dec5], Object.getOwnPropertyDescriptor(_obj3, "showLocationControls"), _obj3)), _obj3)));

        // necessary because topic-title plugin outlet only recieves model
        api.modifyClass("controller:topic", (_dec6 = (0, _decorators.observes)("editingTopic"), (_obj4 = {
          pluginId: "locations-plugin",
          setEditingTopicOnModel() {
            this.set("model.editingTopic", this.get("editingTopic"));
          }
        }, (_applyDecoratedDescriptor(_obj4, "setEditingTopicOnModel", [_dec6], Object.getOwnPropertyDescriptor(_obj4, "setEditingTopicOnModel"), _obj4)), _obj4)));
        api.modifyClass("component:edit-category-settings", (_dec7 = (0, _decorators.default)("category"), (_obj5 = {
          pluginId: "locations-plugin",
          availableViews(category) {
            let views = this._super(...arguments);
            if (category.get("custom_fields.location_enabled") && this.siteSettings.location_category_map_filter) {
              views.push({
                name: _I18n.default.t("filters.map.label"),
                value: "map"
              });
            }
            return views;
          }
        }, (_applyDecoratedDescriptor(_obj5, "availableViews", [_dec7], Object.getOwnPropertyDescriptor(_obj5, "availableViews"), _obj5)), _obj5)));
        const mapRoutes = [`Map`, `MapCategory`, `MapCategoryNone`];
        mapRoutes.forEach(function (route) {
          api.modifyClass(`route:discovery.${route}`, {
            pluginId: "locations-plugin",
            afterModel() {
              this.templateName = "discovery/map";
              return this._super(...arguments);
            }
          });
        });
        const categoryRoutes = ["category", "categoryNone"];
        categoryRoutes.forEach(function (route) {
          api.modifyClass(`route:discovery.${route}`, {
            pluginId: "locations-plugin",
            afterModel(model, transition) {
              if (this.filter(model.category) === "map" && this.siteSettings.location_category_map_filter) {
                transition.abort();
                return this.replaceWith(`/c/${this.Category.slugFor(model.category)}/l/${this.filter(model.category)}`);
              } else {
                return this._super(...arguments);
              }
            }
          });
        });
      });
      _topicStatus.default.reopen((_obj6 = {
        statuses() {
          const topic = this.get("topic");
          const category = this.get("parent.parentView.category");
          let results = this._super(...arguments);
          if ((this.siteSettings.location_topic_status_icon || category && category.get("custom_fields.location_topic_status")) && topic.get("location")) {
            const url = topic.get("url");
            results.push({
              icon: "map-marker-alt",
              title: _I18n.default.t(`topic_statuses.location.help`),
              href: url,
              openTag: "a href",
              closeTag: "a"
            });
          }
          return results;
        }
      }, (_applyDecoratedDescriptor(_obj6, "statuses", [_decorators.default], Object.getOwnPropertyDescriptor(_obj6, "statuses"), _obj6)), _obj6));
      _composer.default.serializeOnCreate("location");
      _composer.default.serializeToTopic("location", "topic.location");
      _navItem.default.reopenClass({
        buildList(category, args) {
          let items = this._super(category, args);

          // Don't show Site Level "/map"
          if (typeof category !== "undefined" && category && category.custom_fields.location_enabled && category.siteSettings.location_category_map_filter) {
            items.push(_navItem.default.fromText("map", args)); // Show category level "/map" instead
          }
          return items;
        }
      });
    }
  };
});