define("discourse/plugins/discourse-locations/discourse/components/add-location-controls", ["exports", "discourse/plugins/discourse-locations/discourse/lib/location-utilities", "discourse-common/utils/decorators", "@ember/component", "@ember/service", "discourse/plugins/discourse-locations/discourse/components/modal/add-location"], function (_exports, _locationUtilities, _decorators, _component, _service, _addLocation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("noText"), _dec2 = (0, _decorators.default)("noText"), _dec3 = (0, _decorators.default)("location", "noText"), _dec4 = (0, _decorators.default)("noText"), (_obj = {
    modal: (0, _service.inject)(),
    classNames: ["location-label"],
    didInsertElement() {
      $(".title-and-category").toggleClass("location-add-no-text", this.get("iconOnly"));
    },
    iconOnly(noText) {
      return noText || this.siteSettings.location_add_no_text;
    },
    valueClasses(noText) {
      let classes = "add-location-btn";
      if (noText) {
        classes += " btn-primary";
      }
      return classes;
    },
    valueLabel(location, noText) {
      return noText ? "" : (0, _locationUtilities.locationFormat)(location, this.site.country_codes, this.siteSettings.location_input_fields_enabled, this.siteSettings.location_input_fields);
    },
    addLabel(noText) {
      return noText ? "" : "composer.location.btn";
    },
    actions: {
      showAddLocation() {
        return this.modal.show(_addLocation.default, {
          model: {
            location: this.get("location"),
            categoryId: this.get("category.id"),
            update: location => {
              if (this._state !== "destroying") {
                this.set("location", location);
              }
            }
          }
        });
      },
      removeLocation() {
        this.set("location", null);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "iconOnly", [_dec], Object.getOwnPropertyDescriptor(_obj, "iconOnly"), _obj), _applyDecoratedDescriptor(_obj, "valueClasses", [_dec2], Object.getOwnPropertyDescriptor(_obj, "valueClasses"), _obj), _applyDecoratedDescriptor(_obj, "valueLabel", [_dec3], Object.getOwnPropertyDescriptor(_obj, "valueLabel"), _obj), _applyDecoratedDescriptor(_obj, "addLabel", [_dec4], Object.getOwnPropertyDescriptor(_obj, "addLabel"), _obj)), _obj)));
});