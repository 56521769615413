define("discourse/plugins/discourse-locations/discourse/components/user-location", ["exports", "@ember/component", "@ember/object", "@glimmer/component", "@ember/service", "@glimmer/tracking", "I18n", "discourse/plugins/discourse-locations/discourse/lib/location-utilities", "@ember/template-factory"], function (_exports, _component, _object, _component2, _service, _tracking, _I18n, _locationUtilities, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="user-location-widget">
    {{d-icon "map-marker-alt"}}
    <div class="location-label">
      {{this.userLocation}}
    </div>
    {{#if this.canShowMap}}
      <div class="map-wrapper">
        <button
          class="widget-button btn btn-default btn-show-map btn-small btn-icon-text"
          type="button"
          {{on "click" this.toggleMap}}
        >
          {{d-icon "far-map"}}
          {{#if this.showMapButtonLabel}}
            {{this.mapButtonLabel}}
          {{/if}}
        </button>
  
        {{#if this.showMap}}
          <div class="map-container small">
            <LocationsMap @mapType="user" @user={{@user}} />
          </div>
        {{/if}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "qkavke1u",
    "block": "[[[10,0],[14,0,\"user-location-widget\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"map-marker-alt\"],null]],[1,\"\\n  \"],[10,0],[14,0,\"location-label\"],[12],[1,\"\\n    \"],[1,[30,0,[\"userLocation\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"canShowMap\"]],[[[1,\"    \"],[10,0],[14,0,\"map-wrapper\"],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"widget-button btn btn-default btn-show-map btn-small btn-icon-text\"],[24,4,\"button\"],[4,[38,2],[\"click\",[30,0,[\"toggleMap\"]]],null],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"far-map\"],null]],[1,\"\\n\"],[41,[30,0,[\"showMapButtonLabel\"]],[[[1,\"          \"],[1,[30,0,[\"mapButtonLabel\"]]],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showMap\"]],[[[1,\"        \"],[10,0],[14,0,\"map-container small\"],[12],[1,\"\\n          \"],[8,[39,3],null,[[\"@mapType\",\"@user\"],[\"user\",[30,1]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@user\"],false,[\"d-icon\",\"if\",\"on\",\"locations-map\"]]",
    "moduleName": "discourse/plugins/discourse-locations/discourse/components/user-location.hbs",
    "isStrictMode": false
  });
  let LocationMapComponent = _exports.default = (_class = class LocationMapComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "site", _descriptor2, this);
      _initializerDefineProperty(this, "showMap", _descriptor3, this);
    }
    get mapButtonLabel() {
      return _I18n.default.t(`location.geo.${this.showMap ? "hide" : "show"}_map`);
    }
    get showMapButtonLabel() {
      return this.args.formFactor !== "card" && !this.site.mobileView;
    }
    get userLocation() {
      let locationText = "";
      if (this.args.user && this.args.user.geo_location) {
        let format = this.siteSettings.location_user_profile_format.split("|");
        let opts = {};
        if (format.length && format[0]) {
          opts["geoAttrs"] = format;
          locationText = (0, _locationUtilities.geoLocationFormat)(this.args.user.geo_location, this.site.country_codes, opts);
        } else {
          locationText = this.args.user.geo_location.address;
        }
      }
      return locationText;
    }
    get canShowMap() {
      return !document.querySelector(".leaflet-container");
    }
    toggleMap() {
      this.showMap = !this.showMap;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showMap", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleMap", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleMap"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LocationMapComponent);
});