define("discourse/plugins/discourse-locations/discourse/components/custom-wizard-field-location", ["exports", "@ember/component", "@glimmer/component", "I18n", "@ember/object", "@ember/service", "@glimmer/tracking", "@ember/template-factory"], function (_exports, _component, _component2, _I18n, _object, _service, _tracking, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LocationForm
    @street={{this.street}}
    @neighbourhood={{this.neighbourhood}}
    @postalcode={{this.postalcode}}
    @city={{this.city}}
    @state={{this.state}}
    @countrycode={{this.countrycode}}
    @geoLocation={{this.geoLocation}}
    @rawLocation={{this.rawLocation}}
    @inputFields={{this.inputFields}}
    @searchOnInit={{this.searchOnInit}}
    @setGeoLocation={{this.setGeoLocation}}
    @searchError={{this.searchError}}
  />
  */
  {
    "id": "TraK9uY5",
    "block": "[[[8,[39,0],null,[[\"@street\",\"@neighbourhood\",\"@postalcode\",\"@city\",\"@state\",\"@countrycode\",\"@geoLocation\",\"@rawLocation\",\"@inputFields\",\"@searchOnInit\",\"@setGeoLocation\",\"@searchError\"],[[30,0,[\"street\"]],[30,0,[\"neighbourhood\"]],[30,0,[\"postalcode\"]],[30,0,[\"city\"]],[30,0,[\"state\"]],[30,0,[\"countrycode\"]],[30,0,[\"geoLocation\"]],[30,0,[\"rawLocation\"]],[30,0,[\"inputFields\"]],[30,0,[\"searchOnInit\"]],[30,0,[\"setGeoLocation\"]],[30,0,[\"searchError\"]]]],null]],[],false,[\"location-form\"]]",
    "moduleName": "discourse/plugins/discourse-locations/discourse/components/custom-wizard-field-location.hbs",
    "isStrictMode": false
  });
  let CustomWizardFieldLocationComponent = _exports.default = (_class = class CustomWizardFieldLocationComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "name", _descriptor2, this);
      _initializerDefineProperty(this, "street", _descriptor3, this);
      _initializerDefineProperty(this, "postalcode", _descriptor4, this);
      _initializerDefineProperty(this, "city", _descriptor5, this);
      _initializerDefineProperty(this, "countrycode", _descriptor6, this);
      _initializerDefineProperty(this, "geoLocation", _descriptor7, this);
      _initializerDefineProperty(this, "rawLocation", _descriptor8, this);
      _defineProperty(this, "context", this.args.wizard.id);
      _defineProperty(this, "includeGeoLocation", true);
      _defineProperty(this, "inputFieldsEnabled", true);
      _defineProperty(this, "layoutName", "javascripts/wizard/templates/components/wizard-field-location");
      _defineProperty(this, "showType", true);
      const existing = this.args.field.value || {};
      const inputFields = this.inputFields;
      inputFields.forEach(f => {
        if (existing[f]) {
          this[f] = existing[f];
        }
      });
      this.geoLocation = existing["geo_location"] || {};
      this.args.field.customCheck = this.customCheck.bind(this);
    }
    customCheck() {
      const required = this.required;
      const hasInput = this.inputFields.some(f => this[f]);
      if (required || hasInput) {
        return this.handleValidation();
      } else {
        return true;
      }
    }
    get inputFields() {
      return this.siteSettings.location_input_fields.split("|");
    }
    handleValidation() {
      let location = {};
      if (this.inputFieldsEnabled && this.inputFields.indexOf("coordinates") > -1 && (this.geoLocation.lat || this.geoLocation.lon)) {
        return this.setValidation(this.geoLocation.lat && this.geoLocation.lon, "coordinates");
      }
      if (this.inputFieldsEnabled) {
        let validationType = null;
        this.inputFields.some(field => {
          const input = this[`${field}`];
          if (!input || input.length < 2) {
            validationType = field;
            return true;
          } else {
            location[field] = input;
          }
        });
        if (validationType) {
          return this.setValidation(false, validationType);
        }
      }
      if (this.includeGeoLocation) {
        let valid = this.geoLocation && this.geoLocation.lat && this.geoLocation.lon;
        let message;
        if (valid) {
          location["geo_location"] = this.geoLocation;
          this.args.field.value = location;
        } else {
          message = "geo_location";
        }
        return this.setValidation(valid, message);
      } else {
        this.args.field.value = location;
        return this.setValidation(true);
      }
    }
    setValidation(valid, type) {
      const message = type ? _I18n.default.t(`location.validation.${type}`) : "";
      this.args.field.setValid(valid, message);
      return valid;
    }
    setGeoLocation(gl) {
      this.name = gl.name;
      this.street = gl.street;
      this.neighbourhood = gl.neighbourhood;
      this.postalcode = gl.postalcode;
      this.city = gl.city;
      this.state = gl.state;
      this.geoLocation = {
        lat: gl.lat,
        lon: gl.lon
      };
      this.countrycode = gl.countrycode;
      this.rawLocation = gl.address;
    }
    searchError(error) {
      this.flash = error;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "name", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "street", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "postalcode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "city", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "countrycode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "geoLocation", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        lat: "",
        lon: ""
      };
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "rawLocation", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "inputFields", [_object.computed], Object.getOwnPropertyDescriptor(_class.prototype, "inputFields"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setGeoLocation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setGeoLocation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchError", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "searchError"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CustomWizardFieldLocationComponent);
});