define("discourse/plugins/discourse-locations/discourse/components/location-label-container", ["exports", "discourse-common/utils/decorators", "@ember/component", "@ember/runloop"], function (_exports, _decorators, _component, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("topic.location.geo_location"), _dec2 = (0, _decorators.default)("locationAttrs", "geoAttrs"), (_obj = {
    classNames: ["location-label-container"],
    locationAttrs: [],
    geoAttrs: [],
    showMapToggle(geoLocation) {
      return geoLocation && this.siteSettings.location_topic_map;
    },
    opts(locationAttrs, geoAttrs) {
      let opts = {};
      if (locationAttrs) {
        opts["attrs"] = locationAttrs;
      }
      if (geoAttrs) {
        opts["geoAttrs"] = geoAttrs;
      }
      return opts;
    },
    didInsertElement() {
      $(document).on("click", (0, _runloop.bind)(this, this.outsideClick));
    },
    willDestroyElement() {
      $(document).off("click", (0, _runloop.bind)(this, this.outsideClick));
    },
    outsideClick(e) {
      if (!this.isDestroying && !($(e.target).closest(".map-expand").length || $(e.target).closest(".map-attribution").length || $(e.target).closest(".location-topic-map").length)) {
        this.set("showMap", false);
      }
    },
    actions: {
      showMap() {
        this.toggleProperty("showMap");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "showMapToggle", [_dec], Object.getOwnPropertyDescriptor(_obj, "showMapToggle"), _obj), _applyDecoratedDescriptor(_obj, "opts", [_dec2], Object.getOwnPropertyDescriptor(_obj, "opts"), _obj)), _obj)));
});