define("discourse/plugins/discourse-locations/discourse/components/location-form", ["exports", "discourse/plugins/discourse-locations/discourse/lib/location-utilities", "discourse/lib/ajax", "@ember/object", "@ember/object/computed", "@ember/array", "@ember/service", "@glimmer/component", "@glimmer/tracking", "I18n"], function (_exports, _locationUtilities, _ajax, _object, _computed, _array, _service, _component, _tracking, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let LocationForm = _exports.default = (_class = class LocationForm extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "site", _descriptor2, this);
      _initializerDefineProperty(this, "geoLocationOptions", _descriptor3, this);
      _initializerDefineProperty(this, "internalInputFields", _descriptor4, this);
      _initializerDefineProperty(this, "provider", _descriptor5, this);
      _initializerDefineProperty(this, "hasSearched", _descriptor6, this);
      _initializerDefineProperty(this, "searchDisabled", _descriptor7, this);
      _initializerDefineProperty(this, "showProvider", _descriptor8, this);
      _initializerDefineProperty(this, "showGeoLocation", _descriptor9, this);
      _initializerDefineProperty(this, "countrycodes", _descriptor10, this);
      _initializerDefineProperty(this, "loadingLocations", _descriptor11, this);
      _initializerDefineProperty(this, "showLocationResults", _descriptor12, this);
      _initializerDefineProperty(this, "formStreet", _descriptor13, this);
      _initializerDefineProperty(this, "formNeighbourhood", _descriptor14, this);
      _initializerDefineProperty(this, "formPostalcode", _descriptor15, this);
      _initializerDefineProperty(this, "formCity", _descriptor16, this);
      _initializerDefineProperty(this, "formState", _descriptor17, this);
      _initializerDefineProperty(this, "formCountrycode", _descriptor18, this);
      _initializerDefineProperty(this, "formLatitude", _descriptor19, this);
      _initializerDefineProperty(this, "formLongitude", _descriptor20, this);
      _initializerDefineProperty(this, "geoLocation", _descriptor21, this);
      _defineProperty(this, "context", null);
      _defineProperty(this, "showTitle", (0, _computed.equal)("appType", "discourse"));
      if (this.showInputFields) {
        this.internalInputFields = this.args.inputFields;
        this.searchDisabled = true;
        this.internalInputFields.forEach(f => {
          this[`show${f.charAt(0).toUpperCase() + f.substr(1).toLowerCase()}`] = true;
          this[`form${f.charAt(0).toUpperCase() + f.substr(1).toLowerCase()}`] = this.args[f];
          if (["street", "neighbourhood", "postalcode", "city"].includes(f)) {
            this.searchDisabled = false;
          }
        });
        if (this.args.disabledFields) {
          this.args.disabledFields.forEach(f => {
            this.set(`${f}Disabled`, true);
          });
        }
        const hasCoordinates = this.internalInputFields.indexOf("coordinates") > -1;
        if (hasCoordinates && this.args.geoLocation) {
          this.formLatitude = this.args.geoLocation.lat;
          this.formLongitude = this.args.geoLocation.lon;
        }
        const geocoding = this.siteSettings.location_geocoding;
        this.showGeoLocation = geocoding !== "none";
        this.showLocationResults = geocoding === "required";
        if (this.searchOnInit) {
          this.send("locationSearch");
        }
      }
      const siteCodes = this.site.country_codes;
      if (siteCodes) {
        this.countrycodes = siteCodes;
      } else {
        (0, _ajax.ajax)({
          url: "/locations/countries",
          type: "GET"
        }).then(result => {
          this.countrycodes = result.geo;
        });
      }
    }
    get showInputFields() {
      if (this.args.inputFieldsEnabled === false) {
        return false;
      }
      return this.args.inputFieldsEnabled || this.siteSettings.location_input_fields_enabled;
    }
    get showAddress() {
      return !this.showInputFields || this.showInputFields && this.internalInputFields.filter(f => f !== "coordinates").length > 0;
    }
    get providerDetails() {
      return _locationUtilities.providerDetails[this.provider || this.siteSettings.location_geocoding_provider];
    }
    keyDown(e) {
      if (this.showGeoLocation && e.keyCode === 13) {
        this.send("locationSearch");
      }
    }
    get searchLabel() {
      return _I18n.default.t(`location.geo.btn.${this.siteSettings.location_geocoding}`);
    }
    updateGeoLocation(gl, force_coords) {
      if (!this.showInputFields) {
        gl = this.geoLocation;
      }
      gl["zoomTo"] = true;
      if (force_coords) {
        gl.lat = this.formLatitude;
        gl.lon = this.formLongitude;
      } else {
        this.formLatitude = gl.lat;
        this.formLongitude = gl.lon;
      }
      if (gl.address && this.siteSettings.location_auto_infer_street_from_address_data && gl.address.indexOf(gl.city) > 0) {
        gl.street = gl.address.slice(0, gl.address.indexOf(gl.city)).replace(/,(\s+)?$/, "");
      }
      this.internalInputFields.forEach(f => {
        if (f === "coordinates") {
          this.formLatitude = gl.lat;
          this.formLongitude = gl.lon;
        } else {
          this[`form${f.charAt(0).toUpperCase() + f.substr(1).toLowerCase()}`] = gl[f];
        }
      });
      this.args.setGeoLocation(gl);
      this.geoLocationOptions.forEach(o => {
        (0, _object.set)(o, "selected", o["address"] === gl["address"]);
      });
    }
    clearSearch() {
      this.geoLocationOptions.clear();
      this.args.geoLocation = null;
    }
    locationSearch() {
      let request = {};
      const searchInputFields = this.internalInputFields.concat(["countrycode", "context"]);
      searchInputFields.map(f => {
        request[f] = this[`form${f.charAt(0).toUpperCase() + f.substr(1).toLowerCase()}`];
        if (f === "coordinates") {
          request["lat"] = this.formLatitude;
          request["lon"] = this.formLongitude;
        }
      });
      if ($.isEmptyObject(request)) {
        return;
      }
      this.showLocationResults = true;
      this.loadingLocations = true;
      this.hasSearched = true;
      this.showProvider = false;
      (0, _locationUtilities.geoLocationSearch)(request, this.siteSettings.location_geocoding_debounce).then(result => {
        if (this._state === "destroying") {
          return;
        }
        if (result.provider) {
          this.provider = result.provider;
        }
        this.showProvider = result.locations.length > 0;
        this.geoLocationOptions.setObjects(result.locations);
        if (this.geoLocation) {
          this.updateGeoLocation(this.geoLocation);
        }
        this.loadingLocations = false;
      }).catch(error => {
        this.args.searchError(error);
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "geoLocationOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _array.A)();
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "internalInputFields", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "provider", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hasSearched", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "searchDisabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showProvider", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "showGeoLocation", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "countrycodes", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "loadingLocations", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "showLocationResults", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "formStreet", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "formNeighbourhood", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "formPostalcode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "formCity", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "formState", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "formCountrycode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "formLatitude", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "formLongitude", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "geoLocation", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateGeoLocation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateGeoLocation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearSearch", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "locationSearch", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "locationSearch"), _class.prototype)), _class);
});