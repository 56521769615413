define("discourse/plugins/discourse-locations/discourse/connectors/user-location-and-website/replace-location", ["exports", "@ember/runloop"], function (_exports, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(args, component) {
      const enabled = component.siteSettings.location_users_map;
      if (enabled) {
        (0, _runloop.scheduleOnce)("afterRender", () => {
          $(".user-main .location-and-website").addClass("map-location-enabled");
        });
        component.set("showUserLocation", !!args.model.custom_fields?.geo_location);
        component.set("linkWebsite", !args.model.isBasic);
      }
    }
  };
});